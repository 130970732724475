<template>
	<div style="height: 100%;">
		<main-table>
			<el-form slot="header" :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-input v-model="params.keyword" placeholder="请输入申请流水/姓名/手机号码/电子邮箱/身份证/银行卡/现居地址" clearable></el-input>
				</el-form-item>
				<el-form-item v-if="!admin_user.shop_id">
					<el-select v-model="params.shop_id" placeholder="城市站点" filterable multiple collapse-tags>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.employees" placeholder="请选择申请人" filterable multiple collapse-tags>
						<el-option v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.place_id" placeholder="出生首次登记归属地" filterable multiple collapse-tags>
						<el-option v-for="region in fields.regions" :key="region.id" :label="region.name" :value="region.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.census_id" placeholder="身份证件签发归属地" filterable multiple collapse-tags>
						<el-option v-for="region in fields.regions" :key="region.id" :label="region.name" :value="region.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select placeholder="请选择证件类型" v-model="params.type" filterable multiple collapse-tags>
						<el-option v-for="(edu, e) in fields.id_types" :key="e" :label="edu" :value="e"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select placeholder="请选择性别" v-model="params.gender" filterable multiple collapse-tags>
						<el-option v-for="(edu, e) in gendes" :key="e" :label="edu" :value="e"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select placeholder="请选择民族" v-model="params.nation" filterable multiple collapse-tags>
						<el-option v-for="(edu, e) in fields.nations" :key="e" :label="edu" :value="e"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select placeholder="请选择政治面貌" v-model="params.politics" filterable multiple collapse-tags>
						<el-option v-for="(edu, e) in fields.politics" :key="e" :label="edu" :value="e"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select placeholder="请选择教育情况" v-model="params.edu" filterable multiple collapse-tags>
						<el-option v-for="(edu, e) in fields.edus" :key="e" :label="edu" :value="e"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.status" placeholder="状态" filterable  multiple collapse-tags>
						<el-option v-for="(status, s) in fields.status" :key="s" :label="status" :value="s"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select slot="label" v-model="params.date_type" placeholder="请选择时间类型" filterable multiple collapse-tags>
						<el-option label="出生日期" value="birthed"></el-option>
						<el-option label="入职日期" value="hired"></el-option>
						<el-option label="登记时间" value="created"></el-option>
					</el-select>
					<el-date-picker v-model="params.date" :picker-options="$config.DATE_PICKER" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getEnrollments({ ...params, page: 1})">查询</el-button>
				</el-form-item>
			</el-form>
			<el-table slot="table" ref="enrollment_table" class="bill scroll-wrapper absolute" height="100%" :data="tables" :size="theme.size">
				<el-table-column label="登记流水" min-width="180">
					<el-link slot-scope="scope" type="primary" @click="$refs['enrollment'].open(scope.row);" :disabled="!$utils.update($api.URI_EMPLOYEES_ENROLLMENTS)">{{scope.row.no}}</el-link>
				</el-table-column>
				<el-table-column label="姓名" prop="name" min-width="80"></el-table-column>
				<el-table-column label="性别" min-width="50" :formatter="({gender}) => { return gendes[gender] || '未知';}"></el-table-column>
				<el-table-column label="婚姻" min-width="50" :formatter="({marriage}) => { return marriage ? '已婚' : '未婚';}"></el-table-column>
				<el-table-column label="民族" min-width="50" :formatter="({nation}) => { return fields.nations[nation] || '未知';}"></el-table-column>
				<el-table-column label="籍贯" min-width="60" prop="place.name"></el-table-column>
				<el-table-column label="户籍" min-width="60" prop="census.name"></el-table-column>
				<el-table-column label="证件信息" min-width="160">
					<template slot-scope="scope">{{fields.id_types[scope.row.id_type] || '未知' }}<br>{{scope.row.id_number}}</template>
				</el-table-column>
				<el-table-column label="政治面貌" min-width="70" :formatter="(r) => { return fields.politics[r.politics] || '未知';}"></el-table-column>
				<el-table-column label="学校专业" min-width="80">
					<template slot-scope="scope">{{scope.row.graduation}}<br>{{scope.row.profession}}</template>
				</el-table-column>
				<el-table-column label="最高学历" min-width="70" :formatter="(r) => { return fields.edus[r.education] || '未知';}"></el-table-column>
				<el-table-column label="银行账户" min-width="120">
					<template slot-scope="scope">{{scope.row.bank_number}}<br>{{scope.row.deposit_bank}}</template>
				</el-table-column>
				<el-table-column label="联系方式" min-width="120">
					<template slot-scope="scope">{{scope.row.email}}<br>{{scope.row.current_address}}</template>
				</el-table-column>
				<el-table-column label="紧急联系" min-width="120">
					<template slot-scope="scope">{{scope.row.emergency_mobile}}<br>{{scope.row.emergency_name}}</template>
				</el-table-column>
				<el-table-column label="出生日期" min-width="100">
					<template slot-scope="scope">{{scope.row.birthed_at | format_at}}</template>
				</el-table-column>
				<el-table-column label="入职日期" min-width="100">
					<template slot-scope="scope">{{scope.row.hired_at | format_at}}</template>
				</el-table-column>
				<el-table-column label="登记时间" prop="created_at" min-width="160"></el-table-column>
				<el-table-column label="状态" min-width="80">
					<template slot-scope="scope">
						<el-tooltip effect="dark" placement="top" :content="'OA审核编号'+scope.row.approval.sp_no" v-if="scope.row.approval">
							<span>{{fields.status[scope.row.status] || '未知'}}</span>
						</el-tooltip>
						<span v-else>{{fields.status[scope.row.status] || '未知'}}</span>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getEnrollments({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getEnrollments({ ...params, page: v }) }"
				background>	
			</el-pagination>
		</main-table>
		<enrollment-show ref="enrollment" @refresh="getEnrollments(params)"></enrollment-show>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import moment from 'moment';
	import mainTable from '@/components/main-table';
	import enrollmentShow from '@/pages/hrm/enrollments/show';

	export default {
		components: {
			mainTable,
			enrollmentShow
		},
		computed: {
			...mapState(['theme', 'admin_user', 'models']),
			fields () {
				return this.models.enrollments || {};
			},
			shops () {
				return this.models.shops ? this.models.shops.data : [];
			}
		},
		filters: {
			format_at (t) {
				if (!t) return '无';
				return moment(t).format('Y-MM-DD');
			}
		},
		methods: {
			async getEnrollments (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_EMPLOYEES_ENROLLMENTS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.tables = result.data;
				this.employees = result.employees;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				tables: [],
				gendes: ['男', '女'],
				employees: [],
				enrollment: {},
				params: {
					perPage: 10,
				}
			}
		},
		created () {
			this.getEnrollments(this.params, true);
		}
	};
</script>