<template>
	<el-dialog width="520px" :title="person ? '个人档案' : '编辑档案'" :visible.sync="enrollment_dialog" append-to-body destroy-on-close>
		<div class="scroll-wrapper" style="max-height: 58vh; margin-top: -30px; overflow: auto; padding-right: 10px;">
			<div style="position: relative;" v-if="is_show">
				<el-descriptions :labelStyle="{width: '70px'}" :title="'No.'+enrollment.no" :column="2" :size="theme.size" border>
					<template slot="extra" v-if="person && enrollment.status == 4">
						<el-button type="primary" :size="theme.size" @click="enrollment.id = 0;">更新资料</el-button>
						<el-button type="danger" :size="theme.size" @click="enrollment_dialog = false; $emit('departure')">离职申请</el-button>
					</template>
					<el-descriptions-item label="姓名">{{enrollment.name}}</el-descriptions-item>
					<el-descriptions-item label="婚姻">{{enrollment.marriage ? '已婚' : '未婚'}}</el-descriptions-item>
					<el-descriptions-item label="性别">{{genders[enrollment.gender] || 'N/A'}}</el-descriptions-item>
					<el-descriptions-item label="民族">{{fields.nations[enrollment.nation] || 'N/A'}}</el-descriptions-item>
					<el-descriptions-item label="籍贯">{{enrollment.place ? enrollment.place.name : 'N/A'}}</el-descriptions-item>
					<el-descriptions-item label="户籍">{{enrollment.census ? enrollment.census.name : 'N/A'}}</el-descriptions-item>
					<el-descriptions-item label="政治">{{fields.politics[enrollment.politics] || 'N/A'}}</el-descriptions-item>
					<el-descriptions-item label="学历">{{fields.edus[enrollment.education] || 'N/A'}}</el-descriptions-item>
					<el-descriptions-item label="学校">{{enrollment.graduation}}</el-descriptions-item>
					<el-descriptions-item label="专业">{{enrollment.profession || '无'}}</el-descriptions-item>
					<el-descriptions-item label="证件类型" v-if="!fields.id_types[enrollment.id_type]">未知</el-descriptions-item>
					<el-descriptions-item :label="fields.id_types[enrollment.id_type]" v-else>{{enrollment.id_number}}</el-descriptions-item>
					<el-descriptions-item label="银行账户">{{enrollment.deposit_bank}}<br/>{{enrollment.bank_number}}</el-descriptions-item>
					<el-descriptions-item label="出生日期">{{enrollment.birthed_at | format_at}}</el-descriptions-item>
					<el-descriptions-item label="入职日期">{{enrollment.hired_at | format_at}}</el-descriptions-item>
					<el-descriptions-item label="紧急联系">{{enrollment.emergency_mobile}}，{{enrollment.emergency_name}}</el-descriptions-item>
					<el-descriptions-item label="电子邮箱">{{enrollment.email}}</el-descriptions-item>
					<el-descriptions-item label="现居地址" :span="2">{{enrollment.current_address}}</el-descriptions-item>
					<el-descriptions-item label="相关证件" :span="2">
						<template v-if="person">
							<div class="e-attachments" v-if="enrollment.attachments.length">
								<p v-for="attachment in enrollment.attachments" @click="openFile(attachment.url)" :key="attachment.id">
									<i class="el-icon-document"></i> <span>{{attachment.name}}</span>
								</p>
							</div>
							<p v-else>无</p>
						</template>
						<el-form ref="enrollment_form" :model="enrollment" :rules="enrollment_rules" :size="theme.size" v-else status-icon>
							<el-form-item>
								<p v-for="attachment in enrollment.attachments.filter((a) => a.meta == 0)" @click="openFile(attachment.url)" :key="attachment.id">
									<i class="el-icon-document"></i> <span>{{attachment.name}}</span>
								</p>
							</el-form-item>
							<el-form-item prop="credentials">
								<el-upload
									:action="$api.URI_UPLOADS"
									:data="{type: enrollments_cfg.type}"
									:accept="enrollments_cfg.ext.map(e => { return '.'+e; }).join(',')"
									:on-success="(r, f, l) => { onSuccess(l, 'credentials'); }"
									:on-preview="(f) => { openFile(f.url); }"
									:disabled="enrollment.status != 4"
									:file-list="enrollment.attachments.filter((a) => a.meta == 1)">
									<el-button type="text" size="mini" icon="el-icon-upload">点击上传</el-button>
									<p slot="tip" style="font-size: 12px; color: #999; line-height: 1.5;">只能上传 {{enrollments_cfg.ext.join('/').toUpperCase()}} 文件，且大小不超过 {{enrollments_cfg.format_size_unit}}<br>电子证照、身份证件、学历证明、技能证书、银行卡等入职签字证明</p>
								</el-upload>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="submitForm('enrollment_form')">更新证件</el-button>
							</el-form-item>
						</el-form>
					</el-descriptions-item>
				</el-descriptions>
				<div style="color: rgba(255, 0, 0, 0.2); position: absolute; top: 100px; left: 50%; font-size: 38px; font-weight: bold; transform: translate(-50%, -50%) rotateZ(20deg);" v-if="fields.status[enrollment.status]">{{fields.status[enrollment.status]}}</div>
			</div>
			<el-form label-width="90px" ref="enrollment_form" :model="enrollment" :rules="enrollment_rules" :size="theme.size" v-else status-icon>
				<el-form-item label="姓名" prop="name">
					<el-input v-model="enrollment.name" placeholder="请输入真实姓名" clearable>
						<el-switch slot="append" v-model="enrollment.marriage" :active-value="1" :inactive-value="0" active-text="已婚" inactive-text="未婚"></el-switch>
					</el-input>
				</el-form-item>
				<el-form-item label="民族/性别" style="margin-bottom: 0;" required>
					<el-col :span="11">
						<el-form-item prop="nation">
							<el-select v-model="enrollment.nation" placeholder="民族" filterable clearable>
								<el-option v-for="(nation, n) in fields.nations" :key="n" :label="nation" :value="+n"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col style="text-align: center;" :span="2">-</el-col>
					<el-col :span="11">
						<el-form-item prop="gender">
							<el-radio-group v-model="enrollment.gender">
								<el-radio-button :label="1">男</el-radio-button>
								<el-radio-button :label="2">女</el-radio-button>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item label="籍贯/户籍" style="margin-bottom: 0;" required>
					<el-col :span="11">
						<el-form-item prop="place_id">
							<el-select style="width: 100%;" v-model="enrollment.place_id" placeholder="出生首次登记归属地" filterable clearable>
								<el-option label="无" :value="0"></el-option>
								<el-option v-for="region in fields.regions" :key="region.id" :label="region.name" :value="region.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col style="text-align: center;" :span="2">-</el-col>
					<el-col :span="11">
						<el-form-item prop="census_id">
							<el-select style="width: 100%;" v-model="enrollment.census_id" placeholder="身份证件签发归属地" filterable clearable>
								<el-option label="无" :value="0"></el-option>
								<el-option v-for="region in fields.regions" :key="region.id" :label="region.name" :value="region.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item label="证件号码" prop="id_number">
					<el-input v-model="enrollment.id_number" placeholder="请输入证件号码" clearable>
						<el-select slot="prepend" style="width: 120px;" v-model="enrollment.id_type" placeholder="证件类型" filterable clearable>
							<el-option v-for="(type, t) in fields.id_types" :key="t" :label="type" :value="+t"></el-option>
						</el-select>
					</el-input>
				</el-form-item>
				<el-form-item label="现居地址" prop="current_address">
					<el-input v-model="enrollment.current_address" placeholder="请输入现居地址" clearable></el-input>
				</el-form-item>
				<el-form-item label="政治/学历" style="margin-bottom: 0;" required>
					<el-col :span="11">
						<el-form-item prop="politics">
							<el-select style="width: 100%;" v-model="enrollment.politics" placeholder="政治面貌" filterable clearable>
								<el-option v-for="(politics, p) in fields.politics" :key="p" :label="politics" :value="+p"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col style="text-align: center;" :span="2">-</el-col>
					<el-col :span="11">
						<el-form-item prop="education">
							<el-select style="width: 100%;" v-model="enrollment.education" placeholder="最高学历" filterable clearable>
								<el-option v-for="(education, e) in fields.edus" :key="e" :label="education" :value="+e"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item label="学校/专业" style="margin-bottom: 0;" required>
					<el-col :span="11">
						<el-form-item prop="graduation">
							<el-input v-model="enrollment.graduation" placeholder="请输入毕业学校" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col style="text-align: center;" :span="2">-</el-col>
					<el-col :span="11">
						<el-form-item prop="profession">
							<el-input v-model="enrollment.profession" placeholder="请输入毕业专业" clearable></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item label="紧急联系" style="margin-bottom: 0;" required>
					<el-col :span="11">
						<el-form-item prop="emergency_name">
							<el-input v-model="enrollment.emergency_name" placeholder="请输入紧急联系人" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col style="text-align: center;" :span="2">-</el-col>
					<el-col :span="11">
						<el-form-item prop="emergency_mobile">
							<el-input v-model="enrollment.emergency_mobile" placeholder="请输入紧急联系手机" clearable></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item label="出生/入职" style="margin-bottom: 0;" required>
					<el-col :span="11">
						<el-form-item prop="birthed_at">
							<el-date-picker style="width: 100%;" v-model="enrollment.birthed_at" :editable="false" type="date" placeholder="请选择出生日期"></el-date-picker>
						</el-form-item>
					</el-col>
					<el-col style="text-align: center;" :span="2">-</el-col>
					<el-col :span="11">
						<el-form-item prop="hired_at">
							<el-date-picker style="width: 100%;" v-model="enrollment.hired_at" :editable="false" type="date" placeholder="请选择入职日期"></el-date-picker>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item label="开户/卡号" style="margin-bottom: 0;" required>
					<el-col :span="11">
						<el-form-item prop="deposit_bank">
							<el-input v-model="enrollment.deposit_bank" placeholder="请输入开户银行" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col style="text-align: center;" :span="2">-</el-col>
					<el-col :span="11">
						<el-form-item prop="bank_number">
							<el-input v-model="enrollment.bank_number" placeholder="请输入银行卡号" clearable></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item label="电子邮箱" prop="email">
					<el-input v-model="enrollment.email" placeholder="请输入电子邮箱" clearable></el-input>
				</el-form-item>
				<el-form-item label="相关证件" prop="attachments">
					<el-upload
						:action="$api.URI_UPLOADS"
						:data="{type: enrollments_cfg.type}"
						:accept="enrollments_cfg.ext.map(e => { return '.'+e; }).join(',')"
						:on-success="(r, f, l) => { onSuccess(l, 'attachments'); }"
						:on-preview="(f) => { openFile(f.url); }"
						:file-list="enrollment.attachments">
						<el-button type="text" size="mini" icon="el-icon-upload">点击上传</el-button>
						<p slot="tip" style="font-size: 12px; color: #999; line-height: 1.5;">只能上传 {{enrollments_cfg.ext.join('/').toUpperCase()}} 文件，且大小不超过 {{enrollments_cfg.format_size_unit}}<br>电子证照、身份证件、学历证明、技能证书、银行卡等入职签字证明</p>
					</el-upload>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('enrollment_form')">提交审核</el-button>
				</el-form-item>
			</el-form>
		</div>
	</el-dialog>
</template>

<style>
	.e-attachments p {
		cursor: pointer;
		padding: 5px 10px;
		border-radius: 5px;
	}
	.e-attachments p:hover {
		background-color: #f1f1f1;
	}
	.e-attachments p:hover span {
		color: #b7a06a;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import moment from 'moment';

	export default {
		computed: {
			...mapState(['theme', 'models']),
			fields () {
				return this.models.enrollments || {};
			},
			enrollments_cfg () {
				return this.$utils.uploadConfig('enrollments');
			},
			is_show () {
				if (!this.enrollment.id) return false;
				if (this.enrollment.status == 2) return true;
				if (this.enrollment.status == 4) return true;
				return false;
			}
		},
		props: {
			person: {
				default: false,
				type: Boolean
			}
		},
		filters: {
			format_at (t) {
				if (!t) return '无';
				return moment(t).format('Y-MM-DD');
			}
		},
		methods: {
			openFile (u) {
				window.open(u);
			},
			async open (enrollment = {}) {
				this.enrollment = { ...enrollment };
				this.enrollment_dialog = true;
			},
			onSuccess (list, meta) {
				this.enrollment[meta] =  list.map((file) => {
					const { response } = file;
					if (response) {
						const { status, id } = response.result;
						if (status == "success") {
							file.id = id;
						}
					}
					return file;
				});
			},
			async submitEnrollment (data) {
				const res = await this.$http.post(this.$api.URI_EMPLOYEES_ENROLLMENTS, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({
					message,
					onClose: () => {
						this.$emit('refresh');
						this.enrollment_dialog = false;
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'enrollment_form':
							this.submitEnrollment(this.enrollment);
						break;
					}
				});
			}
		},
		data() {
			const checkEmail = (rule, value, callback) => {
				const reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
				if (value && !reg.test(value)) return callback(new Error('请输入正确的电子邮箱'));
				return callback();
			}
			const checkMobile = (rule, value, callback) => {
				const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
				if (value && !reg.test(value)) return callback(new Error('请输入正确的手机号'));
				return callback();
			}
			return {
				genders: ['未知', '男', '女'],
				enrollment: {},
				enrollment_dialog: false,
				enrollment_rules: {
					name: [{ required: true, message: '必须填写真实姓名', trigger: 'blur'}],
					gender: [{ required: true, message: '必须选择性别', trigger: 'blur'}],
					email: [{ validator: checkEmail, trigger: ['blur', 'change'] }],
					education: [{ required: true, message: '必须填写教育情况', trigger: 'blur'}],
					id_number: [{ required: true, message: '必须填写证件号码', trigger: 'blur'}],
					bank_number: [{ required: true, message: '必须填写银行账户', trigger: 'blur'}],
					graduation: [{ required: true, message: '必须填写毕业学校', trigger: 'blur'}],
					emergency_name: [{ required: true, message: '必须填写紧急联系人', trigger: 'blur'}],
					emergency_mobile: [
						{ required: true, message: '必须填写手机号码', trigger: 'blur'},
						{ validator: checkMobile, trigger: ['blur', 'change'] }
					],
					current_address: [{ required: true, message: '必须填写现居地址', trigger: 'blur'}],
					birthed_at: [{ required: true, message: '必须填写出生日期', trigger: 'blur'}],
					hired_at: [{ required: true, message: '必须填写入职日期', trigger: 'blur'}],
				}
			}
		}
	};
</script>